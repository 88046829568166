var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('div',[_c('div',[_vm._m(0),_c('div',{staticClass:"title-tabs mt-3"},[_vm._v("Set up Promotion")]),_c('b-container',{staticClass:"no-gutters bg-white py-2"},[_c('div',{staticClass:"py-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12","xs":"12"}},[_c('InputText',{attrs:{"textFloat":"Promotion Name","placeholder":"Promotion Name","type":"text","name":"promotions_name","isRequired":"","isValidate":_vm.$v.form.name.$error,"v":_vm.$v.form.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12","xs":"12"}},[_c('InputText',{attrs:{"textFloat":"Promotions Ref.","placeholder":"Promotions Ref.","type":"text","name":"promotions-ref","isRequired":"","isValidate":_vm.$v.form.promotion_ref.$error,"v":_vm.$v.form.promotion_ref},model:{value:(_vm.form.promotion_ref),callback:function ($$v) {_vm.$set(_vm.form, "promotion_ref", $$v)},expression:"form.promotion_ref"}})],1),_c('div',{staticClass:"break-normal"}),_c('b-col',{staticClass:"mb-md-0 mb-3",attrs:{"lg":"6","md":"6","sm":"12","xs":"12"}},[_c('label',{staticClass:"label-text"},[_vm._v(" Valid From "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('div',{class:[
                  'input-container',
                  { error: _vm.$v.form.start_datetime.$error },
                ]},[_c('datetime',{ref:"start_datetime",attrs:{"type":"datetime","placeholder":"Please Select Date","format":"dd/MM/yyyy (HH:mm)","value-zone":"Asia/Bangkok"},on:{"input":_vm.handleMinDate},model:{value:(_vm.form.start_datetime),callback:function ($$v) {_vm.$set(_vm.form, "start_datetime", $$v)},expression:"form.start_datetime"}}),_c('div',{class:'icon-primary text-right ' + _vm.id == 0
                      ? ''
                      : 'cursor-default',on:{"click":function($event){_vm.$refs.start_datetime.isOpen = true}}},[_c('font-awesome-icon',{class:_vm.id == 0
                        ? 'pointer color-primary'
                        : 'cursor-default color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.$v.form.start_datetime.$error)?_c('div',[(_vm.$v.form.start_datetime.required == false)?_c('span',{staticClass:"text-error"},[_vm._v("Please select date time.")]):_vm._e()]):_vm._e()]),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12","xs":"12"}},[_c('label',{staticClass:"label-text"},[_vm._v(" Valid To "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('div',{class:[
                  'input-container',
                  { error: _vm.$v.form.end_datetime.$error },
                ]},[_c('datetime',{ref:"end_datetime",attrs:{"type":"datetime","placeholder":"Please Select Date","format":"dd/MM/yyyy (HH:mm)","value-zone":"Asia/Bangkok","min-datetime":_vm.form.start_datetime},model:{value:(_vm.form.end_datetime),callback:function ($$v) {_vm.$set(_vm.form, "end_datetime", $$v)},expression:"form.end_datetime"}}),_c('div',{class:'icon-primary text-right ' + _vm.id == 0
                      ? ''
                      : 'cursor-default',on:{"click":function($event){_vm.$refs.end_datetime.isOpen = true}}},[_c('font-awesome-icon',{class:_vm.id == 0
                        ? 'pointer color-primary'
                        : 'cursor-default color-primary',attrs:{"icon":"calendar-alt","color":"#B41BB4"}})],1)],1),(_vm.$v.form.end_datetime.$error)?_c('div',[(_vm.$v.form.end_datetime.required == false)?_c('span',{staticClass:"text-error"},[_vm._v("Please select date time.")]):_vm._e()]):_vm._e()])],1),_c('hr')],1),_c('div',{staticClass:"panelborder"},[_c('div',{staticClass:"title-tabs"},[_vm._v(" Promotions Items "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('div',{staticClass:"px-3 py-2"},[_c('TableProductSelect',{ref:"productPromotionsSelect",attrs:{"useBy":"promotions","filter":_vm.filter,"fields":_vm.fields,"items":_vm.items,"rows":_vm.rows,"freezeProductList":_vm.freezeProductList,"form":_vm.form,"v":_vm.$v.form.add_product},on:{"syncProductSelect":_vm.syncProductSelect,"pagination":_vm.pagination,"handleChangeTake":_vm.handleChangeTake,"handleSearch":_vm.handleSearch}}),(_vm.$v.form.add_product.$error)?_c('div',{staticClass:"text-error"},[(
                  this.form.add_product.length == 0 &&
                  this.form.sap_product.lenght == 0
                )?_c('span',[_vm._v("Please select at least 1 product.")]):_c('span',[_vm._v("Please Set Promotion Price")])]):_vm._e(),(_vm.$v.form.sap_product.$error)?_c('div',{staticClass:"text-error"},[(this.form.add_product.length == 0)?_c('span',[_vm._v("Please select at least 1 product.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"px-3 py-2",attrs:{"name":"branch-select"}},[_c('div',{staticClass:"mb-2 main-label"},[_vm._v(" Participating Branches "),_c('span',{staticClass:"text-error"},[_vm._v("*")])]),_c('ModalSelectBranch',{ref:"ModalSelectBranch",attrs:{"list":_vm.branchList,"form":_vm.form,"id":"1","isBranchDisable":_vm.isDisabled,"text":"Select Branch"},on:{"selectBranch":_vm.selectBranch}}),(_vm.$v.form.branch_id.$error)?_c('div',{staticClass:"text-error"},[_vm._v(" Please select at least 1 branch. ")]):_vm._e()],1)])]),_c('div',{staticClass:"no-gutters bg-white mt-3 py-2 px-3"},[_c('b-form-checkbox',{staticClass:"radio-active",attrs:{"switch":"","size":"lg","value":1,"unchecked-value":0},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('span',{staticClass:"ml-2 main-label"},[_vm._v(_vm._s(_vm.form.status ? "Active" : "Inactive"))])])],1),_c('FooterAction',{attrs:{"routePath":"/promotions"},on:{"submit":function($event){return _vm.saveForm()}}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"mr-sm-4 header-tablepage"},[_vm._v("PROMOTION DETAIL")])])
}]

export { render, staticRenderFns }